import Swiper from 'swiper/bundle';

new Swiper('#hero_swiper', {
   observer: true,
   keyboard: {
      enabled: true,
      onlyInViewport: false,
   },
   navigation: {
      nextEl: `.hero_swiper-container  .swiper-arrow.next`,
      prevEl: `.hero_swiper-container  .swiper-arrow.prev`
   },
   effect: 'fade',
   speed: 600,
   parallax: true,
   crossFade: true,
   loop: true,
   // autoplay: true,
   delay: 5000,
   // autoHeight: true,
   pauseOnMouseEnter: true,
   breakpoints: {
      // 650: {
      //    autoHeight: false
      // }
   }
})