import sendForm from '../../js/module/sendForm.js';

const popupFormTitle = document.querySelector('.popup-title')
const popupFormSubject = document.querySelector('.popup-wrap [type="hidden"]')

document.querySelectorAll('.open_form').forEach(item => {
   item.addEventListener('click', function () {
      let attr = this.dataset.name

      if (attr) {
         popupFormTitle.innerText = attr
         popupFormSubject.value = attr
      } else {
         popupFormTitle.innerText = 'Свяжитесь с нами'
         popupFormSubject.value = 'Сообщение из формы'
      }
   })
})


document.querySelectorAll('.form').forEach(form => {
   form.addEventListener('submit', async function (e) {
      e.preventDefault();
      await sendForm(this)
   })
})