const delivery = document.querySelectorAll('.delivery')
const deliveryWrap = document.querySelector('.delivery-wrap')


window.addEventListener('load', function () {
   const deliveryActive = deliveryWrap.querySelector('.delivery.active')
   let windowWidth = window.innerWidth
   deliveryTab(delivery, deliveryActive, windowWidth)
   
   delivery.forEach((item)=> {
      item.addEventListener('click', () => {
         deliveryTab(delivery, item, windowWidth)
      })
   })
})

window.addEventListener('resize', function () {
   const deliveryActive = deliveryWrap.querySelector('.delivery.active')
   let windowWidth = window.innerWidth
   deliveryTab(delivery, deliveryActive, windowWidth)
   
   delivery.forEach((item)=> {
      item.addEventListener('click', () => {
         deliveryTab(delivery, item, windowWidth)
      })
   })
})


function deliveryTab (arr, activeItem, windowWidth) {
   let container = activeItem.querySelector('.container')

   for (let item of arr) {
      item.classList.remove('active')
      if (windowWidth <= 900) item.style.maxHeight = '100px'
      
   }
   
   if (windowWidth <= 900) activeItem.style.maxHeight = `${container.clientHeight}px`
   activeItem.classList.add('active')
}